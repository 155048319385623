*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%
  overflow: auto;

  // !!always the larger media query before the smaller one
  @include respond(big-desktop) {
    // width >1800 ?
    font-size: 75%; //1rem = 12px, 12/16 = 75%
  }
  @include respond(tab-land) {
    // width <1200 ?
    font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    // width <900 ?
    font-size: 50%; //1rem = 8px, 8/16 = 50%
  }
}

body {
  box-sizing: border-box;
  overflow: hidden;
}

::selection {
  background-color: $color-primary;
  color: $color-tertiary;
}

.development {
  padding-top: 4rem;
  padding-bottom: 8rem;
  &__repo {
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 1rem;
      font-size: 2rem;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30rem;
    height: 6rem;
    margin: 2rem 0;
    text-align: center;
    border-radius: 4px;
    background-color: $color-font-dark;
    border: none;
    font-size: 3rem;
    font-family: $h2-font-family;
    color: $color-font-light;
    box-shadow: $shadow-normal;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: none;

    ion-icon {
      // margin-right: 1rem;
      font-size: 3rem;
    }

    &:hover,
    &:active {
      transform: scale(0.96);
      box-shadow: $shadow-pressed;
    }
  }
}

.btnLaunch {
  display: grid;
  align-items: center;
  width: 25rem;
  height: 6rem;
  margin: 6rem 0;
  text-align: center;
  border-radius: 4px;
  background-color: $color-font-dark;
  border: none;
  font-size: $h2-font-size;
  font-family: $h2-font-family;
  color: $color-font-light;
  box-shadow: $shadow-normal;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;

  &:hover,
  &:active {
    transform: scale(0.96);
    box-shadow: $shadow-pressed;
  }
}

.footer {
  min-height: 30vh;
  background-color: $color-tertiary;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;

  &__list {
    max-width: 100rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    @include respond(phone) {
      max-width: 30rem;
      grid-template-columns: repeat(2, 1fr);
    }
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
  &__item {
    text-align: center;
    list-style: none;
    @include respond(phone) {
      margin-bottom: 5rem;
    }
  }
  &__link {
    display: inline-block;
    text-decoration: none;
    font-size: $label-font-size;
    color: $color-font-dark;
    transition: all 0.2s;
    font-weight: bold;

    &:hover,
    &:active {
      color: $color-font-dark-active;
      // color: red;
    }
  }

  &__logo {
    list-style: none;
    text-align: center;
    @include respond(phone) {
      grid-column: 1 / 3;
      grid-row: 5 / 6;
    }
  }

  &__img {
    width: 12rem;
    display: block;
    margin-bottom: 2rem;
  }

  &__name {
    color: $color-font-dark;
    font-size: 2rem;
  }

  &__copyright {
    color: $color-font-dark;
    font-weight: bold;
    font-size: 1.2rem;
  }
}

// MEDIA QUERIES MANAGER
/*
0 - 600px:        Phone
600 - 900px:      Tablet Portrait
900 - 1200px:     Tablet landscape
[1200 - 1800px:]    Desktop - Where our normal styles apply
1800px + :      Big Desktop 

$breakpoint argument choices:
- phone 
- tab-port
- tab-land
- desktop
- big-desktop

Order: Base - typography - general layout - page layout - components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } // 900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } // 1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}

// Visibility
.u-hide {
  visibility: hidden !important;
}

// CENTER
.u-center-text {
  text-align: center !important;
}

// MARGINS
// Bottom
.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

// Top
.u-margin-top-small {
  margin-top: 1.5rem !important;
}
.u-margin-top-medium {
  margin-top: 4rem !important;
}
.u-margin-top-big {
  margin-top: 8rem !important;
}

// WIDTHS
.u-max-width-small {
  max-width: 75rem;
  margin: 0 auto;
  @include respond(phone) {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.u-max-width-medium {
  max-width: 90rem;
  margin: 0 auto;
  @include respond(tab-port) {
    max-width: 75rem;
  }
  @include respond(phone) {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}
.u-max-width-big {
  max-width: 120rem;
  margin: 0 auto;
  @include respond(tab-land) {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

// COLOR
.u-color-light {
  color: $color-font-light;
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: $color-primary;
  color: $color-font-light;
  text-align: center;
  line-height: 1;

  &__logo {
    width: 30rem;

    // #left {
    //   transform: translateX(-6rem);
    // }
    // #right {
    //   transform: translateX(6rem);
    // }

    .cls-2 {
      fill: url(#linear-gradient);
    }

    .cls-3 {
      fill: #a5a5a5;
      opacity: 0.3;
    }

    .cls-4 {
      fill: #fff;
      opacity: 0.5;
    }

    .draw {
      stroke: #f25c68;
      stroke-width: 5px;
      stroke-dasharray: 750;
      stroke-dashoffset: 750;
    }

    .cls-5 {
      fill: url(#linear-gradient-2);
    }

    .cls-6 {
      fill: url(#New_Gradient_Swatch_copy_3);
    }

    .cls-7 {
      fill: url(#linear-gradient-3);
    }

    .cls-8 {
      fill: url(#linear-gradient-4);
    }

    .cls-9 {
      fill: url(#New_Gradient_Swatch_copy_3-2);
    }

    .cls-10 {
      fill: url(#New_Gradient_Swatch_copy_3-3);
    }
  }

  &__nav {
    // visibility: hidden;
  }

  &__link {
    text-decoration: none;
    color: $color-font-light;
    margin: 0 0.7rem;
  }

  &__dividers {
    color: $color-accent-1;
    font-size: 1.2rem;
  }

  .paragraph {
    margin-bottom: 6rem;
    // visibility: hidden;
  }

  .heading-primary {
    margin-bottom: 0.3rem;
    @include respond(tab-port) {
      font-size: 6rem;
    }
  }
}

.competencies {
  background-color: $color-secondary;
  color: $color-font-light;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem 0 8rem 0;

  &__title {
    font-size: 1.8rem;
    text-transform: uppercase;
  }

  .paragraph {
    line-height: 1.4;
  }

  .paragraph:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.about {
  background-color: $color-tertiary;
  padding-top: 25rem;
  @include respond(phone) {
    padding-top: 18rem;
  }

  .keyboard {
    background-color: $color-secondary;
    width: 100%;

    &__shape {
      margin-top: -6rem;
      margin-bottom: -6rem;
      box-shadow: $shadow-normal;
      border-radius: 8px;
    }

    .cls-2,
    .cls-5 {
      fill: #fff;
    }
    .cls-3 {
      fill: $color-secondary;
    }
    .cls-4,
    .cls-5 {
      font-size: 22.7px;
      font-family: Slenco-Black, Slenco;
      font-weight: 800;
    }
    .back {
      fill: $color-grey;
    }
  }
  &__aboutme {
    background-color: $color-primary;
    color: $color-font-light;
    padding-bottom: 10rem;
  }

  &__wave {
    background-color: $color-secondary;
    .shape-fill {
      fill: $color-primary;
    }
    svg {
      position: relative;
      display: block;
      width: calc(177% + 1.3px);
      height: 21.5rem;
      @include respond(tab-port) {
        height: 15rem;
      }
      @include respond(phone) {
        height: 10rem;
      }
    }
  }

  &__video {
    max-width: 100%;
    margin: 0px auto;
    margin-bottom: 2.5rem;

    .container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0px;
      & > div {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }
    }
  }

  h1 span {
    color: $color-accent-2;
  }

  .paragraph {
    .br {
      display: block;
      margin-bottom: 1rem;
    }
  }
}

.introduction {
  padding-top: 10rem;
  padding-bottom: 10rem;

  &__info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
    margin: 4rem auto;
    @include respond(phone) {
      grid-template-columns: 1fr;
    }
  }

  &__col-1 {
  }

  &__col-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    .paragraph {
      font-weight: bold;
    }

    ion-icon {
      margin-right: 1rem;
      font-size: 2rem;
    }
  }

  span {
    color: $color-accent-2;
  }
}

// COLORS
$color-primary: #130929;
$color-secondary: #870ce5;
$color-tertiary: #fff;
$color-accent-1: #cd25bd;
$color-accent-2: #f25c68;
$color-background-desk: #d2cee0;
$color-white: #fff;
$color-grey: #dfdfdf;

// COLOR FONTS
$color-font-dark: #161616;
$color-font-dark-active: #727272;
$color-font-light: #dadada;
// $color-font-light-active: rgb(218, 218, 218);

// FONTS
$h1-font-family: 'Slenco', sans-serif;
$h1-font-size: 6rem;

$h2-font-family: 'Slenco', sans-serif;
$h2-font-size: 4rem;

$h3-font-family: 'Slenco', sans-serif;
$h3-font-size: 1.8rem;

$p-font-family: 'Poppins', sans-serif;
$p-font-size: 1.6rem;

$label-font-family: 'Poppins', sans-serif;
$label-font-size: 1.4rem;

$btn-font-size: 1.4rem;

// IMAGES
$border-radious: 5px;

// SHADOWS
$shadow-normal: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
  0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11),
  0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);

$shadow-pressed: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
  0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);

.header {
  display: block;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  .social {
    margin: 3rem;
    visibility: hidden;
    &__link {
      color: $color-font-light;
      &:not(:last-child) {
        margin-right: 1.2rem;
      }
    }

    ion-icon {
      font-size: 20px;
    }
  }
}

.navigation {
  position: fixed;
  top: 0;
  right: 0;
  // visibility: hidden;
  // background-color: red;
  z-index: 10;
  // mix-blend-mode: difference;

  &__nav {
    // visibility: hidden;
    transform: translateX(100%);
    position: fixed;
    right: 0;
    width: 60rem;
    height: 100vh;
    z-index: 20;
    background-color: $color-tertiary;
    pointer-events: none;
    // mix-blend-mode: normal !important;

    @include respond(phone) {
      width: 100%;
    }
    &.open {
      pointer-events: auto;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    visibility: hidden;
  }

  &__list {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style-type: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    margin-right: 10rem;
    opacity: 0;
    transform: translateX(200px);
  }

  &__braket {
    // visibility: hidden;
    opacity: 0;
    display: block;
    width: 2rem;
    height: 100%;
    margin-bottom: 0.5rem;
    transform: translateX(-30px) rotateY(-90deg);

    &--right {
      transform: rotate(180deg) translateX(-30px) rotateY(90deg);
    }
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-family: $h2-font-family;
      font-size: $h2-font-size;
      padding: 1rem 2rem;
      color: $color-font-dark;
      text-decoration: none;
      text-transform: uppercase;

      @include respond(phone) {
        font-size: 3rem;
      }

      span {
        margin-right: 1.5rem;
        display: inline-block;
      }
    }

    &:hover,
    &:active {
      color: $color-secondary;
    }

    // &:hover .navigation__braket {
    //   transform: translateX(100px);
    // }
  }
}

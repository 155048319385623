.projects {
  background-color: $color-tertiary;
  &__wave {
    background-color: $color-secondary;
    position: relative;
    z-index: 1;
    svg {
      position: relative;
      display: block;
      width: calc(161% + 1.3px);
      height: 25.5rem;
      @include respond(tab-port) {
        height: 20rem;
      }
      @include respond(phone) {
        height: 10rem;
      }
    }
  }
  &__wave-fill {
    // fill: $color-secondary;
    fill: $color-tertiary;
  }

  &__table {
    // margin-bottom: 12rem;
    @include respond(phone) {
      margin-top: 6rem;
    }
  }

  &__select {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 26rem);
    grid-gap: 5rem;
    a {
      text-decoration: none;
    }
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 26rem);
    }
    @include respond(phone) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 30rem);
    }
  }

  span {
    color: $color-accent-2;
  }
}

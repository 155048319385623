.contact {
  padding: 10rem 0;
  background-color: $color-grey;

  &__form {
    color: $color-font-dark;
    text-transform: uppercase;
  }
  &__label {
    width: 100%;
    display: block;
    line-height: 0.3;
    &:not(:first-child) {
      margin-top: 2.5rem;
    }
  }

  &__input,
  &__text-area {
    margin-top: 2rem;
    width: 100%;
    display: block;
    border-radius: $border-radious;
    padding: 2rem;
    border: none;
    font-family: $label-font-family;
    font-size: $label-font-size;

    &:focus {
      outline: none;
    }
  }

  &__input {
    height: 5rem;
  }

  &__text-area {
    min-height: 22rem;
    margin-bottom: 5rem;
  }

  &__btn {
    width: 100%;
    height: 5rem;
    background-color: $color-accent-2;
    color: $color-font-dark;
    font-size: 1.6rem;
    font-family: $p-font-family;
    text-transform: uppercase;
    border-radius: $border-radious;
    border: none;
    box-shadow: $shadow-normal;
    cursor: pointer;
    transition: all 0.3s;

    &:hover,
    &:active {
      transform: scale(0.99);
      box-shadow: $shadow-pressed;
    }
  }

  span {
    color: $color-accent-2;
  }
}
